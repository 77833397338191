#site-footer{
    @extend .py-5;
    background-color: $light-primary;
    font-size: $font-small;
    color: $primary-light;
    a{
        color: $primary-light;
        text-decoration: none;
    }
    .externalyticslogo, .openeconomicslogo{
        max-width: 315px;
        height: auto;
        @extend .my-4;
        @include media-breakpoint-down(sm) {
            max-width: 242px;
        }
    }
    .credits{
        @extend .py-4;
        @extend .justify-content-center;
        .credit-logo{
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
        }
      img{
        &.grey{
          filter: grayscale(100%);
          opacity: 0.4;
          height:46px;
        }
        @extend .img-fluid;
        @extend .mx-3
      }
    }
    .copyright{
        @extend .pt-4;
    }
    .footer-menu{
        @extend .py-4;
        .footer-menu-item{
            display: inline-block;
            border-right: 1px solid $primary-light;
            @extend .px-3;
            &:last-child {
                border-right: 0px;
            }
            @include media-breakpoint-down(sm) {
                border-right: 0px;
                margin-bottom: 15px;
                display: block;
            }
        }
        .symbol{
            fill:$primary;
          }
    }
}