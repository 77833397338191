.min-height-box {
  min-height: 13.8rem;
}

.link-additional-info {
  color: $second-color;
  font-size: 17px;

  &:hover {
    color: $second-color;
  }
}

.attachments-dashboard {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.donut-title {
  text-align: center;
  margin-top: 1rem;

  button {
    color: #000 !important;
    &:hover,
    &:visited {
      color: #000 !important;
    }
  }
}

.impactCard-title {
  margin-top: 1rem;
  color: $second-color;

  h5 {
    text-align: left !important;
  }
}

.mainmenu-dashboard,
.submenu-dashboard {
  // border-bottom: 1px solid $third-color;
  border-bottom-left-radius: 5px;
}

.dashSubMenu-buttons,
.dashSubMenu-buttons:hover,
.dashSubMenu-buttons:visited,
.dashSubMenu-buttons:focus {
  text-decoration: none;
}

#dropdown-file-view-toggle,
.show > .btn-primary.dropdown-toggle {
  background-color: $second-color !important;
  border-color: $second-color !important;
  &::after {
    border-color: #ffffff !important;
  }
}
#dropdown-file-view .dropdown-item {
  &.active {
    background-color: $second-color !important;
    color: #fff !important;
  }
  &:active {
    background-color: $second-color !important;
    color: #fff !important;
  }
}

#dashboardCarousel {
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2308a2aa' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2308a2aa' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  }

  .carousel-indicators li {
    background-color: #08a2aa7a;
  }

  .carousel-indicators li.active {
    background-color: #08a2aa;
  }
}

.glass-background {
  background: rgba(255, 255, 255, 0.35);

  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
}

.fileViewerTransition {
  transition: all 0.3s ease-in-out;
}
