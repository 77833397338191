code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scenari {
  background-color: #e5e5e5;
}

.box {
  width: 75%;
  border: 1px solid #ddd;
  margin: 0 auto;
}
.simple-box .steps ul {
  overflow: hidden;
}
.simple-box .steps ul li div {
  color: #999;
  padding: 10px 0 15px 45px;
  position: relative;
  background: #f5f5f5;
}
.simple-box .steps ul li div span {
  font-size: 13px;
}
.simple-box .steps ul li:first-child div {
  padding-left: 15px;
}
.simple-box .steps ul li div::before {
  content: ' ';
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 1;
  margin-left: 1px;
}
.simple-box .steps ul li div::after {
  content: ' ';
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.simple-box .steps ul li.done div {
  border-color: #20a8d8 !important;
  color: #fff !important;
  background: #20a8d8 !important;
}
.simple-box .steps ul li.done div::after {
  border-left: 30px solid #20a8d8;
}
.simple-box .steps ul li.active div {
  border-color: #167495 !important;
  color: #fff !important;
  background: #167495 !important;
}
.simple-box .steps ul li.active div::after {
  border-left: 30px solid #167495;
}

.simple-box .step-component {
  padding: 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.simple-box .btn-component {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.scrollable {
  overflow: auto;
}
.investment-table td,
.sroi-table td {
  padding: 5px;
  font-size: 0.9rem;
}
.investment-table td input {
  padding: 0 2px;
  width: 45px;
  font-size: 0.8rem;
}

.table td {
  padding: 3px;
  font-size: 0.9rem;
  white-space: nowrap;
}
.table td input {
  padding: 0 0px;
  width: 45px;
  font-size: 0.8rem;
  height: 25px;
}

.responsive-table > table {
  width: 100%;
  border-collapse: collapse;
  -webkit-overflow-scrolling: touch;
}

.main-wrapper {
  width: 70vw;

  overflow-x: auto;

  box-sizing: border-box;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 999;
}

.sticky-second {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: -117;
  z-index: 999;
}

.btn svg {
  margin: 0;
}

.submenu-ico {
  margin-right: 3px !important;
  margin-left: 7px !important;
}

.submenu-i {
  margin-left: 7px;
  margin-right: 6px;

  svg {
    margin-left: 6px !important;
    height: 100% !important;
    margin-bottom: 3px;
  }
}

.tabname {
  margin-bottom: 0.5rem;
}

.ico-header {
  width: 23px;
  height: 23px;
  fill: $second-color;

  &.battery {
    width: 30px;
    height: 30px;
  }
}

.old-folder {
  a.nav-link.active {
    border: 0px solid transparent !important;
  }

  a:last-child {
    &:before {
      border: 0;
    }
  }

  a:first-child {
    &:after {
      border-left: 25px solid $light-primary;
    }
    &.active:after {
      border-left: 25px solid $second-color;
    }
  }

  a {
    background-color: #fff !important;
    border-color: #e5e5e5 !important;
    text-transform: uppercase;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 1rem 0;
    color: #000 !important;
    position: relative;

    &.active {
      font-size: 1.4rem;
      background-color: $second-color !important;
      color: white !important;
      z-index: 1;
      position: relative;
      color: #fff;

      svg {
        fill: white;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 25px solid white;
      border-top: 45px solid transparent;
      border-bottom: 45px solid transparent;
    }

    &:before {
      content: '';
      position: absolute;
      right: -24px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 25px solid $light-primary;
      border-top: 47px solid transparent;
      border-bottom: 47px solid transparent;
      filter: drop-shadow(2px 0px 0px $primary-lightest);
      z-index: 1;
    }

    &.active:before {
      border-left: 25px solid $second-color;
    }

    &:hover {
      isolation: auto !important;
    }

    &.disabled {
      color: $primary-lighter !important;

      svg.ico-header {
        fill: $second-color-trasparent !important;
      }
    }
  }
}

.folder {
  a.nav-link.active {
    border: 0px solid transparent !important;
  }

  a:last-child {
    &:before {
      border: 0;
    }
  }

  a:first-child {
    &:after {
      border-left: 25px solid $light-primary;
    }
    &.active:after {
      border-left: 25px solid $second-color;
    }
  }

  a {
    background-color: #fff !important;
    border-color: #e5e5e5 !important;
    text-transform: uppercase;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 1rem 0;
    color: #000 !important;
    position: relative;

    &.active {
      font-size: 1.4rem;
      background-color: $second-color !important;
      color: white !important;
      z-index: 1;
      position: relative;
      color: #fff;

      svg {
        fill: white;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 25px solid white;
      border-top: 45px solid transparent;
      border-bottom: 45px solid transparent;
    }

    &:before {
      content: '';
      position: absolute;
      right: -25px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 26px solid $light-primary;
      border-top: 45px solid transparent;
      border-bottom: 45px solid transparent;
      filter: drop-shadow(2px 0px 0px $primary-lightest);
      z-index: 1;
    }

    &.active:before {
      border-left: 26px solid $second-color;
    }

    &:hover {
      isolation: auto !important;
    }

    &.disabled {
      color: $primary-lighter !important;

      svg.ico-header {
        fill: $second-color-trasparent !important;
      }
    }
  }
}

.sub-folder {
  // padding: 0 1.9rem;

  a.nav-link.active {
    border: 0px solid transparent !important;
  }

  a:last-child {
    &:before {
      border: 0;
    }
  }

  a:first-child {
    &:after {
      border-left: 25px solid $light-primary;
    }
    &.active:after {
      border-left: 25px solid $third-color;
    }
  }

  a {
    background-color: #fff !important;
    border-color: #e5e5e5 !important;
    text-transform: capitalize;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 1rem 0;
    color: #000 !important;
    position: relative;

    &.active {
      font-size: 1.4rem;
      background-color: $third-color !important;
      text-shadow: grey 1px 0 5px;
      color: white !important;
      z-index: 1;
      position: relative;
      color: #fff;

      svg {
        fill: white;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 25px solid white;
      border-top: 28px solid transparent;
      border-bottom: 28px solid transparent;
    }

    &:before {
      content: '';
      position: absolute;
      right: -24px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 25px solid $light-primary;
      border-top: 28px solid transparent;
      border-bottom: 28px solid transparent;
      filter: drop-shadow(2px 0px 0px $primary-lightest);
      z-index: 1;
    }

    &.active:before {
      border-left: 25px solid $third-color;
    }

    &:hover {
      isolation: auto !important;
    }

    &.disabled {
      color: $primary-lighter !important;

      svg.ico-header {
        fill: $third-color-trasparent !important;
      }
    }

    .tabname {
      margin-bottom: 0;
    }
  }
}

#tab-corporateesg.nav-item.nav-link.disabled {
  pointer-events: auto !important;
  cursor: default !important;
}
