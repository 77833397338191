.wrapper {
  position: relative;
  width: 100%;
  height: 95%;
  box-sizing: border-box;
}

.legend {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: end;
  padding-bottom: 5px;
}

.chartTitle {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* font-size: 14px; */
  color: #193c56;
  font-weight: 600;
  z-index: 1;
  align-self: flex-start;
}
.chartTitle:hover {
  overflow: visible;
}

.legendItem {
  display: flex;
  flex-shrink: 0;
  gap: 3px;
}

.legendItemTitle {
  color: #7f7f7f;
  font-size: 9px;
  line-height: 17px;
}
