#loginPage {
  .header-site {
    @extend .py-4;
    .text-login {
      .openeconomicslogo {
        @include media-breakpoint-up(md) {
          max-width: 242px;
          margin-left: 30px;
        }
        @include media-breakpoint-down(md) {
          max-width: 50%;
          margin-left: 30px;
        }
      }
      small {
        font-size: $font-small;
      }
      .externalyticslogo {
        margin-bottom: 20px;
        max-width: 100%;
      }
    }
    .claim {
      @extend .py-3;
      @extend .py-md-0;
      .infoLogin {
        font-size: $font-lg;
        line-height: $font-line-lg;
      }
      .claimLogin {
        font-size: 34px;
        line-height: $font-lg-3;
        color: $second-color;
        font-weight: $font-w-bold;
      }
    }
  }

  .login-container {
    background-color: #dde4e9;
    background-image: url('../img/bg-login.png');
    background-position: center left;
    background-repeat: no-repeat;
    background-size: auto 100%;
    @include media-breakpoint-up(md) {
      padding: 60px 0px;
    }
    @include media-breakpoint-up(lg) {
      padding: 120px 0px;
    }
    .simple-box.form-login {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0px;
      @extend .offset-lg-4;
      padding: 40px 49px 37px 48px;
      text-align: center;
      .btn {
        width: 100%;
      }
      .text-muted {
        padding: 33px 0 35px;
        a {
          color: $second-color;
        }
      }
      input {
        text-align: center;
        font-size: $font-big;
        color: $primary-light;
      }
      .trial-text {
        font-weight: $font-w-bold;
        font-size: $font-big;
        margin-top: 30px;
        a {
          color: $second-color;
          text-decoration: underline;
        }
      }
    }
  }
}

#finance,
#externalytics-text,
#metodologia {
  @extend .py-5;
}
#externalytics-text,
#metodologia {
  border-top: 1px solid $primary-lightest-saturate;
}

.pass-recovery-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.login-bottom-paragraph-style {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-family: Barlow;
}
.login-bottom-button-style {
  color: $second-color;
  font-weight: 600;
}

.login-header-main {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 28.8px !important;
  font-family: 'Barlow' !important;
  color: $second-color;
}

.login-backbutton {
  position: absolute;
  cursor: pointer;
  transform: rotate(90deg);
  margin-bottom: 15px;
  top: -10px;
  left: -30px;
}
