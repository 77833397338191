#wizard {
  background: $primary-lightest;

  .btn.submit-button,
  .btn.upload {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .btn.selection-button {
    max-width: 80%;
    background-color: rgba(8, 162, 170, 0.7);
    border-color: rgba(8, 162, 170, 0.7);
    text-shadow: grey 1px 0 5px;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: rgba(8, 162, 170, 0.7);
      border-color: rgba(8, 162, 170, 0.7);
      text-shadow: grey 1px 0 5px;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(8, 162, 170, 0.5);
    }
  }

  .btn.selection-button-outlined {
    max-width: 80%;
    background-color: $light-primary;
    border-color: rgba(8, 162, 170, 0.7);
    color: rgba(8, 162, 170, 0.7);
    text-shadow: rgb(191, 190, 190) 0.5px 0 2px;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $light-primary;
      border-color: rgba(8, 162, 170, 0.7);
      text-shadow: rgba(8, 162, 170, 0.7) 0.5px 0 2px;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(8, 162, 170, 0.5);
    }
  }

  .btn-nav {
    background-color: $third-color;
    border-color: $third-color;
    color: #ffffff;
  }

  .btn-nav .active {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }
  .btn-nav .active {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .nav {
    color: $primary-lighter;

    .active {
      color: $first-color;
    }
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    table-layout: fixed;
    width: 100%;
  }

  table tbody {
    display: table;
    width: 100%;
  }

  a {
    color: $primary-light;

    &:hover,
    &:visited {
      color: $primary-light;
    }
  }

  .main-wrapper {
    width: 100%; //width: 70vw;

    overflow-x: auto;

    box-sizing: border-box;
    display: table;
    table-layout: fixed;
  }

  .impact {
    .chartBox {
      //padding: 1em 3em;
      text-align: center;

      button {
        background-color: $first-color;
        border-color: $first-color;
      }

      h5 {
        text-align: center;
        // margin-top: .5em;
      }

      &.impactChart {
        //min-width: 210px;
        //width: 16.65%;
        min-width: 8.75rem;
        //width: 20%;
        max-width: 8.75rem;

        h5 {
          text-transform: uppercase;

          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }

        a {
          color: #000;

          &:hover,
          &:visited {
            color: #000;
          }
        }

        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
      }
    }
  }

  .container-card {
    //@extend .d-flex;
    .card,
    .simple-box {
      flex: 1;

      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card {
    width: 100%;
  }
}

#statusbar {
  .simple-box {
    color: #ffffff;
    font-weight: bold;
  }

  .success {
    background-color: #c2c923;
  }

  .error {
    background-color: #cb1b4a;
  }

  .elaborating {
    background-color: #08a2aa;
  }
}

#template-upload-form {
  .form-group {
    margin-bottom: 0px;
  }
}

.addon-for-number {
  //background-color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.gap-2 {
  @media (min-width: 1920px) {
    gap: 2rem;
  }
}

.impact {
  &.header {
    padding: 1.125rem;
  }
}

.submit-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  min-width: 9rem;
  font-size: 1.1rem;

  @media (min-width: 1920px) {
    min-width: 12rem;
  }

  @media (min-width: 4096px) {
    min-width: 15rem;
  }
}

.sticky-footer-button {
  position: sticky;
  bottom: 1.3rem;
  z-index: 1000;
  animation: fadeInTEST 0.3s;
  transition: opacity 0.4s;
  align-items: center;
  justify-content: center;
  left: 50%;
  //transform: translate(-50%, 0);
  &.sticky-footer-button-esg {
    left: 0;
    //transform: translate(-50%, 0);
  }
}

.sroi-table {
  display: table !important;
  width: 100%;

  th,
  td {
    padding: 0.8em 0.6em;
  }

  td {
    border-bottom: 1px solid #dbe4ec;
  }

  tr.total {
    font-weight: bold;

    td {
      border-bottom: none;
    }
  }

  .value-label {
    div.tooltipInfoBox {
      transform: translate(0px, 0px) !important;
    }
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    background-color: #1c2743;
    color: #fff;
  }
  thead th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    top: 0;
    background-color: #1c2743;
    color: #fff;
  }

  tbody tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    background-color: #fff;
  }
  tbody tr th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    left: 0;
    background-color: #fff;
  }

  thead th:first-child {
    left: 0;
    z-index: 10;
  }

  tbody tr th:first-child {
    left: 0;
    z-index: 10;
    min-width: 25rem;
    width: 25rem;
    border-bottom: 1px solid #dbe4ec !important;
  }
}

.th-width {
  width: 25rem;
  min-width: 25rem;
}

.cell-width {
  width: 17rem;
  min-width: 17rem;

  @media (min-width: 2236px) {
    width: 22rem;
    min-width: 22rem;
  }
}

.select-region__control {
  border: 3px solid $third-color !important;
}

.select-region__control--is-focused {
  box-shadow: 0 0 0 1px $third-color !important;
}

.custom-file-label::after {
  display: none;
}

.border-tabs-economics {
  border-right: 3px solid $third-color;
}

#tabs-economics {
  .nav-link.active {
    color: white;
    background-color: $third-color;
  }
}

table.economics-table {
  display: inline-table; //table

  //overflow-x: auto;
  //white-space: nowrap;
  //table-layout: fixed;
  //width: 100%;
  th,
  td {
    padding: 0.8em 0.6em;
    text-align: right;

    &.value-label {
      text-align: left;
    }
  }

  th {
    background-color: #1c2743;
    color: #fff;
  }
}

.timing-input-group {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: 0;
}

//color of switch input
.custom-control {
  z-index: auto;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $second-color;
  background-color: $second-color;
}

.inputgroup {
  &.timing {
    span:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    span:nth-of-type(2n) {
      border-radius: 0;
    }
  }

  &.discountrate,
  &.investment {
    span:first-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.custom-switch .custom-control-label::before {
  //left: -2.25rem;
  //pointer-events: all;
  width: 3rem;
  height: 1.5rem;
  border-radius: 1rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.4rem);
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  background-color: #adb5bd;
  border-radius: 2rem;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $second-color;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem $second-color-trasparent;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $second-color-trasparent;
}

//for switch inline
.custom-control-label {
  padding-left: 1rem;
  cursor: pointer;

  /* &::before {
      //top: 0.05rem !important;
   }
   &::after {
      //top: calc(0.2rem + -1px) !important;
   } */

  span {
    top: 0.15rem;
    position: relative;
    margin-left: 0.5rem;
  }
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  cursor: auto;
}

input[type='checkbox'] {
  accent-color: $second-color;
}

.required-field {
  color: $second-color;
}

.optional-field {
  color: $primary-lighter;
}

.border-radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.text-link {
  color: $second-color !important;
  text-decoration: underline;
}

.select-files__control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

#hiddenFile {
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;
  overflow: hidden;
}

#hiddenFile::file-selector-button {
  display: none;
  overflow: hidden;
}

.pulse-button {
  // border-radius: 50%;
  // margin: 10px;
  // height: 20px;
  //width: 20px;
  transform: scale(1);
  //background: $first-color;
  box-shadow: 0 0 0 0 rgba(28, 39, 67, 1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(28, 39, 67, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(28, 39, 67, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(28, 39, 67, 0);
  }
}

//show economic data switch div
#expenses-localization {
  transition: all 0.5s;
}

#expenses-localization.hide {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

#row-regions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (max-width: 1279px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 2048px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.tab-economic-data {
  a.active {
    color: inherit;
  }

  a {
    color: $second-color;
  }
}

.economic-data-action-buttons {
  @media (max-width: 1250px) {
    width: 23px;
  }
}

.economic-data-summary {
  width: 100%;
  overflow: auto;
  text-align: center;
  display: table !important;

  // @media (min-width: 1920px) {
  //   width: 50% !important;
  //   //margin: auto;
  // }

  /* thead th {
      padding: 10px;
      background-color: $first-color;
      color: #fff;
   } */

  thead th:first-child {
    padding-left: 8px;
    text-align: left;
    //width: 50%;
  }

  tbody {
    display: contents !important;
    border-bottom: 0;

    tr {
      padding: 10px 0;
    }

    tr:first-child {
      border-bottom: 1px solid $second-color; //#dbe4ec
    }

    td {
      padding: 10px;
    }
  }

  tbody td:first-child {
    padding-left: 8px;
    //font-weight: 500;
    text-align: left;
    //width: 50%;
  }

  tbody tr:last-child {
    border-bottom: 0 !important;
  }

  tfoot tr td {
    padding: 1.5rem 8px 10px 10px;
  }

  .totals-row td {
    padding: 1rem 10px;
  }

  svg:hover {
    fill: $second-color;
  }
}
.kpy-data-summary-container {
  width: 100%;
  overflow: auto;
  .kpi-data-summary-table {
    display: table !important;
    table-layout: fixed !important;
    border-collapse: collapse !important;

    thead th {
      padding: 10px;
      background-color: $first-color;
      color: #fff;
    }

    thead th:first-child {
      padding-left: 8px;
      text-align: left;
    }

    tbody {
      border-bottom: 0;

      tr {
        padding: 10px 0;
      }

      tr:first-child {
        border-bottom: 1px solid $second-color; //#dbe4ec
      }

      td {
        padding: 10px 50px;
      }
      .kpi-data-summary-tbody-empty {
        min-width: 150px;
      }
    }

    tbody td:first-child {
      padding-left: 8px;
      //font-weight: 500;
      text-align: left;
      //width: 50%;
    }

    tbody tr:last-child {
      border-bottom: 0 !important;
    }

    tfoot tr td {
      padding: 1.5rem 8px 10px 10px;
    }

    .totals-row td {
      padding: 1rem 10px;
    }

    svg:hover {
      fill: $second-color;
    }
  }
}

.show-economic-data {
  width: 100%;
  margin-top: 2rem;
  overflow: auto;
  text-align: center;

  thead th {
    padding: 10px;
    background-color: $first-color;
    color: #fff;
  }

  thead th:first-child {
    padding-left: 8px;
    text-align: left;
    //width: 50%;
  }

  tbody {
    border-bottom: 0;

    tr {
      padding: 10px 0;
    }

    tr:first-child {
      border-bottom: 1px solid #dbe4ec;
    }

    tr:nth-child(even) {
      background-color: $primary-lightest-1;
    }

    td {
      padding: 10px;
    }
  }

  tbody td:first-child {
    padding-left: 8px;
    //font-weight: 500;
    text-align: left;
    //width: 50%;
  }

  tbody tr:last-child {
    border-bottom: 0 !important;
  }

  tfoot tr td {
    padding: 1.5rem 8px 10px 10px;
  }

  .totals-row td {
    padding: 1rem 10px;
  }

  svg:hover {
    fill: $second-color;
  }
}

.cell-display-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1072px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cell-width-economic-data {
  max-width: fit-content;
}

/* .input-economic-data {
   border: 0;
   padding: 0;
} */

.region-name {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.economic-collapse {
  padding: 2rem;
  padding-bottom: 0;

  .title {
    font-weight: $font-w-medium;
  }

  .value {
    text-align: right;
  }
}

.dot-legend {
  text-shadow: 0 0 4px $light-primary;

  .bullet {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    top: 0.1em;
    position: relative;
    margin-right: 0.5rem;
  }

  .text {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: $font-w-bold;
  }
}

/*.execute-class,
.execute-dataset,
.execute-localization {
    .totalcapex-text {
      //position: absolute;
      max-width: fit-content;
      flex-basis: min-content;
    
      @media (min-width: 1920px) { 
        position: inherit !important;
        max-width: inherit !important;
      }
    
      @media (max-width: 1367px) { 
       flex: 0 0 50%; //col-6
       max-width: 50%; //col-6
     }
   } 
 }*/

.execute-class .col .row,
.execute-dataset .col .row,
.execute-localization .col .row {
  padding: 1rem 0rem 1rem 0.25rem;

  @media (min-width: 2236px) {
    padding: 1rem 0.5rem;
  }

  .accordion-title {
    cursor: pointer !important;
  }
}

.execute-class,
.execute-dataset,
.execute-localization {
  .accordion {
    display: flex;
    flex-direction: column-reverse;
  }

  .accordion-arrow {
    fill: $second-color;
    stroke: $second-color;
  }

  table {
    width: 100%;
    overflow: auto;
    display: table !important;

    thead th:first-child {
      padding-left: 8px;
    }

    tbody {
      display: contents !important;
      border-bottom: 0;

      tr {
        padding: 10px 0;
        border-bottom: 1px solid $second-color;
      }

      tr:last-child {
        border-bottom: 0;
      }

      td {
        padding: 10px;
      }
    }

    tbody td:first-child {
      padding-left: 8px;
      text-align: left;
    }

    tbody tr:last-child {
      border-bottom: 0 !important;
    }

    tfoot tr td {
      padding: 1.5rem 8px 10px 10px;
    }

    .totals-row td {
      padding: 1rem 10px;
    }
  }
}

span .accordion-arrow {
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

div[class~='show'] + span .accordion-arrow {
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.run-button {
  animation: fadeIn linear 500ms, 0.75s linear infinite spinner-border;
  -webkit-animation: fadeIn linear 500ms, 0.75s linear infinite spinner-border;
  -moz-animation: fadeIn linear 500ms, 0.75s linear infinite spinner-border;
  -o-animation: fadeIn linear 500ms, 0.75s linear infinite spinner-border;
  -ms-animation: fadeIn linear 500ms, 0.75s linear infinite spinner-border;
}

.regionInputPercentage {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.invalid-link-input {
  color: red;
  font-size: $font-small;
  font-weight: $font-w-medium;
}

#variant-item {
  // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  .variant-item-wrapper {
    // cursor: pointer;
  }

  .variant-date-wrapper {
    background-color: #08a2aa3d;
    // background-color: $second-color-trasparent;
    border-radius: 15px;
    color: #2c3d68;
    // color: $light-primary;
    font-weight: $font-w-bold;
  }

  .variant-name-wrapper {
    font-weight: $font-w-semibold;
  }
}

.variant-header-wrapper {
  background-color: #f9f9f9;
  border-radius: 5px 5px 0 0;
  min-height: 48px;
}

#variants-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 0px 0px 5px 5px;
  border-top: none;
  .variant-name-form-control {
    font-weight: $font-w-semibold;
    color: #212529;
    font-size: 17px;
    height: calc(1.5em + 0.45rem);
  }
  // #variant-item {
  //   &:hover {
  //     background-color: #f9f9f9;
  //   }
  // }

  #variant-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}
.accordion-title-variant-selector {
  cursor: pointer;
  background-color: $light-primary;
  border-bottom: none;
}

.counter-answers-wrapper {
  background-color: #08a2aa3d;
  // background-color: $second-color-trasparent;
  border-radius: 15px;
  color: #2c3d68;
  // color: $light-primary;
  font-weight: $font-w-bold;
  max-height: 35px;
}
