.overlayWrapper {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayButton {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 2px;
  right: 10px;
  background: transparent;
  border: none;
}

.overlayButton img {
  height: 12px;
  width: 12px;
}

.wrapper {
  position: relative;
  width: clamp(600px, 80vw, 1290px);
  padding: 20px;
  border-radius: 7px;
  background-color: #ffffff;
  box-sizing: border-box;
}
