.styledSvg {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.styledSvg path:hover {
  fill: var(--hoverFillColor);
}
