 
 .legend {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30%;
    
 }
 
 .select {
    font-family: 'Barlow', Arial, Helvetica, sans-serif;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 2;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    border: none;
    outline: none;
 
    border-bottom: 2px solid #193C56;
 }
 
 .select > option:hover {
    background-color: #193C56;
    color: white;
 }
 
 
 .tooltip {
      font-size: 26px;
      font-family: 'Barlow', Arial, Helvetica, sans-serif;
      line-height: 26px;
      color: black;
      text-align: left;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 5px;
      border: 1px solid black;
      padding: 10px;
      position: relative;
      max-width: 310px;
      max-height: 450px;
      z-index: 100;
      overflow: visible;
 }
 
 /* 
 .tooltip::before,
 .tooltip::after {
   content: '';
   position: absolute;
   bottom: 100%;
   left: 19px;
   border: 11px solid transparent;
   border-bottom-color: #dddddd;
 }
 
 .tooltip::after {
   left: 20px;
   border: 10px solid transparent;
   border-bottom-color: #ffffff;
 } */
 
 .tooltip > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
 }
 
 .tooltip div hr:last-of-type {
    display: none;
 }
 
 
 
 .tooltip hr {
    border-top: 1px dashed darkgray;
 }
 
 .tooltip > div > div  {
    display: flex;
    gap: 5px;
 
    & > div  {
       display: flex;
       flex-direction: column;
       gap: 5px;
       justify-content: space-around;
       & > span:first-of-type {
          font-weight: 600;
       }
    }
 }
 
 .tooltip .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
 }
 
 .tooltip .row .label {
    color: darkgray;
    font-weight: bold;
    text-transform: uppercase;
 }
 
 .tooltip .row .value {
    text-transform: capitalize;
    font-weight: bold;
 
    & > span {
       text-align: right;
       width: 100%;
    }
 }