.link-source {
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem 0;
  margin: 1rem 0;
}

.gap-2-5 {
  gap: 2.5rem;
}

#expense-items,
#dataset,
#interventionTypes {
  background-color: $primary-lightest;
}

#class-modal nav a:not(.active),
#dataset-modal nav a:not(.active),
#kpi-modal nav a:not(.active),
#expenseitems-modal nav a:not(.active) {
  color: $second-color;
}

#class-modal .nav-link.disabled,
#dataset-modal .nav-link.disabled,
#kpi-modal .nav-link.disabled,
#expenseitems-modal .nav-link.disabled {
  color: rgba(8, 162, 170, 0.4);
}

.addon-for-field-bm.input-group-text {
  background-color: white;
  border: 0 !important;
  padding-left: 0;
}

.bm-table.show-bm {
  tbody tr {
    border-bottom: 0 !important;
  }

  tr:nth-child(even) {
    background-color: $primary-lightest-1;
  }
}

.bm-table.class {
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  text-align: center;

  thead th {
    padding: 10px 0;
    color: $first-color;
  }

  thead th:first-child {
    padding-left: 8px;
    text-align: left;
    //width: 50%;
  }

  tbody {
    border-bottom: 0;

    tr {
      padding: 10px 0;
      border-bottom: 1px solid #cdcdcd;
    }

    td {
      padding: 10px 0;
    }
  }

  tbody td:first-child {
    padding-left: 8px;
    font-weight: 500;
    text-align: left;
    //width: 50%;
  }

  tbody tr:last-child {
    border-bottom: 0 !important;
  }

  svg:hover {
    fill: $second-color;
  }
}

.cell-width-bm {
  max-width: fit-content;
}

.input-bm {
  border: 0;
  padding: 0;
}

svg.green-lens {
  path {
    fill: $second-color !important;
  }
}

.capexToOpexShare {
  max-width: 5rem;
  display: inline;
}

//hide arrows in input number
.percTotParent {
  min-width: 400px;
}

.percTotParent {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'].percTot {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.total-capex.alert {
  padding: 0.5rem 1rem !important;
  margin-bottom: 0.25rem !important;
}

.customUploadInput {
  border-radius: 0.375rem;
  border: 1px solid rgb(229, 231, 235);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  &:hover {
    border-color: rgb(26, 32, 44);
  }
}

.sub-color {
  color: rgb(107, 114, 128);
  font-size: 0.9rem;
  font-weight: 600;
}

.icon-upload-new {
  height: 1.5rem; /* 20px */
  width: 1.5rem; /* 20px */
  color: #2f3238;
}

.custom-table {
  border-radius: 8px;
  overflow: hidden;
  th {
    color: rgb(107, 114, 128);
    font-weight: bold;
    padding: 12px;
    text-align: left;
  }
  td {
    padding: 12px;
    font-weight: bold;
    text-align: left;
  }
  tr:last-child td {
    border-bottom: none;
  }
}

// .custom-table th {
//   // background-color: #f2f2f2;
//   color: rgb(107, 114, 128);
//   font-weight: bold;
//   padding: 12px;
//   text-align: left;
//   // border-bottom: 1px solid #dee2e6;
// }

// .custom-table td {
//   padding: 12px;
//   font-weight: bold;
//   text-align: left;
//   // border-bottom: 1px solid #dee2e6;
// }

// .custom-table tr:last-child td {
//   border-bottom: none;
// }

@layer primereact {
  .p-button {
    background-color: $second-color !important;
    border: none !important;
    margin-bottom: 3px !important;
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: $second-color !important;
    background: #08a2aa38 !important;
  }
}

.specificyColor {
  accent-color: $second-color;
}
