#wizardheadernew{
  background: $primary-lightest;

  .impact {
    .chartBox {
      padding: 1em 3em;
      h5 {
        text-align: center;
        margin-top: .5em;
      }
      &.impactChart {
        min-width: 210px;
        width: 16.65%;
        h5{
          text-transform: uppercase;
          &.impact-total{
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc{
          @extend .text-center;
          font-size: $font-small;
        }
      }
    }
  }

  .container-card{
    //@extend .d-flex;
    .card, .simple-box{
      flex: 1;
      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw; 
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card{
    
          width: 100%; 
          
  }

}