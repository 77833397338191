.admin-page .menu-item a:hover {
  text-decoration: none;
}

.user-table thead tr th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.example-header {
  font-family: 'Barlow', sans-serif;
  font-size: initial;
  margin: 1rem 0;
}

#page-size,
#custom-table-pagination {
  border: none;
  text-align: center;
  background-color: inherit;
}

.add-user,
.add-organization {
  font-size: initial !important;
}

.show-hr-renderer {
  margin: 0.1rem 0;
}

.link-langtag {
  color: $second-color;
  padding-left: 0.2rem;
  font-size: 0.881rem;

  &:hover {
    text-decoration: none;
    color: $third-color;
  }
}

.custom-disabled:disabled,
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label,
.form-control:disabled ~ .addon-for-number,
.form-control[disabled] ~ .addon-for-number {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 60%);
}

.custom-file-input {
  cursor: pointer;
}

.pass-eye-ico {
  svg {
    width: 20px;
    @extend .mr-0;
    fill: $primary !important;
    .ico {
      fill: $primary !important;
    }
    .esagono {
      display: none;
    }
  }
}

.company-card {
  position: relative;
  width: 100%; /* Adjust as needed */
  max-width: 230px; /* Adjust as needed */
  background-color: white;
  max-height: 200px;
  height: 200px;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &.deactivated {
    filter: blur(3px);
  }

  &:hover {
    transform: scale(1.05);
    filter: none;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as needed */
  width: 100%;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image scales correctly */
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: lightgray; /* Or any other placeholder style */
}

.text-container {
  width: 100%;
  text-align: center;
  margin-top: 10px; /* Adjust margin as needed */
  position: relative;
  overflow: hidden;
  width: 100%; /* Adjust width as needed */
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-container p {
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button-container {
  position: absolute;
  bottom: -5px; /* Initially off-screen */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: bottom 0.3s ease, opacity 0.3s ease;
}

.company-card:hover .button-container {
  bottom: 10px;
  opacity: 1;
}

@media (hover: none) {
  .button-container {
    bottom: 10px;
    opacity: 1;
  }
}
.companies-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 1rem 1.5rem;
  margin: 1.5rem 0 3rem;
  max-height: 500px;
  overflow: auto;
  scrollbar-color: grey rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 7px; /* width of the scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.03); /* color of the track */
  }
  &::-webkit-scrollbar-thumb {
    background: grey; /* color of the scroll thumb */
    border-radius: 5px;
  }
}

.child-companies {
  margin-bottom: 0;
}
