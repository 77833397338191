.svg-tooltip {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple   Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background: rgba(69, 77, 93, 0.9);
  border-radius: 0.1rem;
  color: #fff;
  display: block;
  font-size: 14px;
  max-width: 320px;
  padding: 0.2rem 0.4rem;
  position: absolute;
  text-overflow: ellipsis;
  //white-space: pre;
  z-index: 300;
  visibility: hidden;
}
div.tooltip-waterfall {
  position: absolute;
  text-align: center;
  padding: 0.5rem;
  background: #313639;
  color: #ffffff;
  // border: 1px solid #313639;
  // border-radius: 8px;
  pointer-events: none;
  font-size: 1.3rem;
  text-align: left;
}
