.wrapper {
  width: 100%;
  height: 100%;
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  background-color: #e5e5e5;
}

.container {
  position: relative;
  color: #1c2743;
  height: 100%;
}
.pageHeader {
  font-size: clamp(20px, 2vw, 40px);
  margin: 0;
  color: #1c2743;
  font-weight: 700;
  /* margin-bottom: 10px; */
}

/* function createGlassBackground() {
  const uniformPart = 32 + 465;
  const gradientPart = 64;
  const color = { r: 229, g: 229, b: 229, a: 0.7 };
  const canvas = document.createElement('canvas');
  canvas.width = uniformPart + gradientPart;
  canvas.height = 1;
  const context = canvas.getContext('2d');
  for (let i = 0; i < uniformPart; i++) {
    context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    context.fillRect(i, 0, 1, 1);
  }
  for (let i = 0; i < gradientPart; i++) {
    const byLinear = 1 - i / gradientPart;
    const byExp = Math.pow(1 - i / gradientPart, 2);
    const byTanh = (Math.tanh((0.5 - i / gradientPart) * 4) + 1) / 2;
    const byCos = Math.cos((i / gradientPart) * Math.PI) * 0.5 + 0.5;
    // const gradient = byLinear;
    // const gradient = byExp;
    // const gradient = byTanh;
    // const gradient = byCos;
    const gradient = byCos * 0.25 + byTanh * 0.25 + byExp * 0.25 + byLinear * 0.25;
    // const gradient = byTanh * (1 - byLinear) + byCos * byLinear;
    // const gradient = byCos * 0.5 + byTanh * 0.5;
    // const gradient = byExp * (1 - byLinear) + byTanh * byLinear;
    // const gradient = byExp * (1 - byLinear) + byCos * byLinear;
    context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a * gradient})`;
    context.fillRect(uniformPart + i, 0, 1, 1);
  }
  return canvas.toDataURL('image/png');
} */

.tableContainer {
  box-sizing: border-box;
  position: absolute;
  padding-top: 38px;
  padding-left: 32px;
  padding-right: 64px;
  top: 0px;
  left: 0px;
  max-width: clamp(465px, 35%, 750px);
  width: 100%;
  z-index: 2;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  /* background-color: rgba(229, 229, 229, 0.5); */
  /* background: linear-gradient(90deg, #e5e5e5 0%, rgba(229, 229, 229, 0.76) 76.64%, rgba(229, 229, 229, 0) 129.08%); */
  /* background-image: url(${createGlassBackground()}); */
  background-repeat: repeat-y;
  height: 100%;
  min-height: 950px;
}

/* @media (max-width: 1400px) {
  .tableContainer {
    max-width: calc(465px + 16px + 16px);
  }
} */

.mapContainer {
  width: 100%;
  height: 100%;
  /* min-height: 1000px; */
  position: absolute;
  top: 0px;
  left: 0px;
}
.card {
  box-sizing: border-box;
  width: 100%;
}
.menu {
  height: 77px;
  display: flex;
  align-items: flex-end;
  background-color: white;
}

.logo {
  margin-left: 32px;
  margin-right: 200px;
  align-self: center;
}

.lineChart {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
}

.searchWrapper {
  position: absolute;
  top: 53px;
  right: 32px;
  z-index: 2;
}
.tabsContainer {
  top: 125px;
  left: 541px;
  height: 574px;
  background-color: #fff;
  border-radius: 7px;
  width: 941px;
  margin-top: 10px;
}
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.closeButton img {
  width: 12px;
  height: 12px;
}
