.input {
  border: none;
  background-color: transparent;
  width: 100%;
}

.input:focus {
  transition: opacity 0.3s ease-in-out;
  outline: none;
}

.styledIcon {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  margin: 8px;
}

.wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  background-color: white;
  /* width: ${(props) => (props.$isFocused ? '250px' : '32px')}; */
  height: 32px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px #00000029;
  transition: 0.5s;
}
