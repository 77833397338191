a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}
body {
  font-family: $font-primary;
  font-size: $font-base;
  line-height: 24px;
  overflow-x: hidden;
}
.megaContent {
  padding: 31px 15px;
  @media (min-width: 2560px) {
    padding: 31px 10rem;
    // margin: auto 10rem;
  }
  @media (min-width: 3840px) {
    padding: 31px 15rem;
    // margin: auto 10rem;
  }
}
h2 {
  font-weight: $font-w-semibold;
  font-size: $font-lg;
  font-family: $font-primary-condensed;
  line-height: $font-line-lg;
  text-transform: uppercase;
  margin-bottom: 40px;
  &.title-box {
    color: $second-color;
  }
}

.info-edit {
  font-size: $font-small;
  color: $primary-light;
  @extend .text-right;
}

svg {
  &.second-color {
    .symbol,
    .ico {
      fill: $second-color;
    }
  }
  &.third-color {
    .symbol,
    .ico {
      fill: $third-color !important;
    }
  }
  &.light-color {
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
  &.red-color {
    .symbol,
    .ico {
      fill: #dc3545;
    }
    .stroke,
    .ico {
      stroke: #dc3545;
    }
  }
  &.white-color {
    fill: white;
  }
  &.submenu-color {
    fill: white;
    .stroke,
    .ico {
      fill: $second-color;
    }
  }
  &.ico-small {
    &.edit {
      width: 17px;
      height: 17px;
    }
    &.edit-line {
      width: 20px;
      height: 20px;
    }
    &.copy {
      width: 18px;
      height: 18px;
    }
    &.cancel {
      width: 20.78px;
      height: 20px;
    }
    &.plus {
      width: 20px;
      height: 20px;
    }
    &.indicators {
      width: 25px;
      height: 25px;
    }
    &.expense-items {
      width: 25px;
      height: 25px;
    }
    &.download-upload {
      width: 27px;
      height: 27px;
    }
    &.lens-bm {
      width: 22px;
      height: 25px;
    }
    &.subMenu-ico {
      width: 30px;
      height: 30px;
    }
  }
  &.ico-box-blue {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $first-color;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
  &.ico-box-yellow {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $third-color;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
  &.ico-box-acqua {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: #08a2aa;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
  &.ico-box-red {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $fourth-color-esg;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }

  &.stroke-10-secondo-color {
    path {
      stroke: $second-color;
      stroke-width: 7;
    }
  }
}

.btn {
  border-radius: 5;
  text-transform: uppercase;
  font-weight: $font-w-bold;
  &.btn-info {
    font-size: $font-big;
  }
  @include media-breakpoint-up(sm) {
    svg {
      margin-right: 22.72px;
    }
  }
  &.btn-link {
    padding: 0px;
    color: $second-color;
    svg {
      .symbol,
      .ico {
        fill: $second-color;
      }
    }
  }
  &.btn-primary {
    background-color: $second-color;
    border-color: $second-color;
    text-shadow: grey 1px 0 5px;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $second-color;
      border-color: $second-color;
      text-shadow: grey 1px 0 5px;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(8, 162, 170, 0.5);
    }
  }

  &.btn-success {
    background-color: $third-color;
    border-color: $third-color;
    text-shadow: grey 1px 0 5px;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $third-color;
      border-color: $third-color;
      text-shadow: grey 1px 0 5px;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 201, 35, 0.5);
    }
    svg {
      margin-left: 13.22px;
      filter: drop-shadow(grey 1px 0 5px);
    }
  }
  &.btn-secondary {
    background-color: $first-color;
    border-color: $first-color;
    //text-shadow: grey 1px 0 5px;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $first-color;
      border-color: $first-color;
      //text-shadow: grey 1px 0 5px;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem $first-color-darken;
    }
    svg {
      margin-right: 13.22px;
    }
  }
  &.btn-outline-primary {
    border-color: $second-color;
    color: $second-color;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $light-primary;
      border-color: $second-color;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(8, 162, 170, 0.5);
    }
  }
  &.btn-outline-secondary {
    border-color: $first-color;
    color: $first-color;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $light-primary;
      border-color: $first-color;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem $first-color-darken;
    }
  }
  &.btn-outline-success {
    border-color: $third-color;
    color: $third-color;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $light-primary;
      border-color: $third-color;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 201, 35, 0.5);
    }
  }
}
.card-deck .card {
  margin-bottom: 20px;
}
.card,
.simple-box {
  margin: 20px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  @include media-breakpoint-down(sm) {
    padding: 18px 20px;
  }
  @include media-breakpoint-up(sm) {
    padding: 18px 31.7px;
  }
  .card-body {
    padding: 0;
  }
  .logo-card,
  .logo-box {
    margin-top: -2.8rem;
    float: right;
    @include media-breakpoint-down(sm) {
      margin-right: -15px;
    }
  }
  .card-title,
  .title-simple-box {
    margin-bottom: 0;
  }
  .margin-title-box {
    margin-bottom: 34px;
  }
  .h5,
  h5 {
    font-weight: $font-w-medium;
    font-family: $font-primary-condensed;
    @include media-breakpoint-down(sm) {
      font-size: $font-big;
      line-height: $font-line-big;
    }
    @include media-breakpoint-up(sm) {
      font-size: $font-medium;
      line-height: $font-line-medium;
    }
  }
  .list-group {
    padding-top: 28px;
    .list-group-item {
      padding: 0.68rem 0;
      span,
      strong {
        width: 50%;
      }
    }
  }
  h4 {
    font-weight: $font-w-regular;
    @include media-breakpoint-down(sm) {
      font-size: $font-lg;
      line-height: $font-line-lg-3;
    }
    @include media-breakpoint-up(sm) {
      font-size: $font-xxl;
      line-height: $font-line-xxl;
    }
  }
}
.simple-box {
  background-color: white;
}
.table,
.new-scenarios-table {
  margin-top: 22px;
  th {
    border-top: 0 !important;
  }
  th {
    @include media-breakpoint-up(sm) {
      padding: 12px;
    }
  }
  th {
    @include media-breakpoint-up(sm) {
      padding: 0 12px 25px 12px;
    }
  }
  .ico-small {
    margin: 0 7.79px;
  }
}
.search-table {
  svg {
    margin: 0;
  }
  .search-btn {
    padding: 0;
  }
  input {
    border: 0;
    padding: 0 0 0 20.97px;
    margin: 0;
    &.form-control:focus,
    &.form-control:active {
      border-color: transparent;
      box-shadow: none;
    }
  }
  border: 1px solid $primary-lighter;
  border-radius: 4px;
  margin-left: 11px;
  //padding:0 5px 0 24.36px;
  padding: 0 20px;
  @include media-breakpoint-between(md, lg) {
    &.input-group {
      width: 50%;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 3px;
    border: 0;
    input {
      padding-left: 2px;
    }
  }
}

.main-container {
  @extend .d-flex;
  .desktop-menu {
    @extend .d-none;
    @extend .d-lg-block;
  }
  .content-page {
    @extend .flex-grow-1;
  }
}

.pagination {
  .page-link {
    border: 0;
    font-size: $font-base;
    line-height: $line-height-base;
    color: $primary;
  }
  .active {
    .page-link {
      background: none;
      font-weight: $font-w-bold;
      color: $primary;
    }
  }
}
.btn-toolbar {
  margin: 41px 0 29px;
  .btn-link {
    margin-right: 5px;
    font-size: $font-base;
    color: $primary-light;
    text-transform: uppercase;
    padding: 5px;
    svg {
      margin-right: 0;
      margin-left: 13px;
      height: 32px;
      width: 27.67px;
      .symbol,
      .ico {
        fill: $light-primary;
      }
      .box,
      .esagono {
        fill: $primary-light;
      }
    }
    &.active {
      background-color: $third-color;
      color: $first-color;
      font-weight: $font-w-bold;
      .box,
      .esagono {
        fill: $first-color;
      }
      svg {
        width: 43.24px;
        height: 50px;
        &.tooltip-ico {
          path {
            fill: $light-primary;
          }
        }
      }
    }
  }
}
.btn-subtoolbar {
  margin: 0px 0 0px;
  .btn-link {
    font-size: $font-small-1;
    color: $primary-light;
    svg {
      height: 23px;
      width: 23px;
    }
    &.active {
      background-color: $second-color;
      color: $light-primary;
      svg {
        width: 27px;
        height: 27px;
      }
    }
  }
}

.tab-box {
  .nav-tabs {
    text-transform: uppercase;
    @extend .d-flex;
    @extend .justify-content-center;
    margin-bottom: 57px;
    @include media-breakpoint-down(md) {
      margin-bottom: 37px;
    }
    a {
      color: $primary-light;
      font-weight: $font-w-semibold;
      &.active {
        color: $second-color;
        border: 0;
        margin-bottom: -1px;
        border-bottom: 3px solid $second-color;
        &:hover,
        &:focus {
          border-bottom: 3px solid $second-color;
        }
      }
      &:hover,
      &:focus {
        border-color: #fff #fff #e9ecef #fff;
        color: $second-color;
      }
    }
    p,
    button {
      margin-bottom: 18px;
    }
  }
  .tab-content {
    ul {
      padding-left: 18px;
      li {
        padding: 9px 0;
      }
    }
  }
}

.light-box-link {
  position: relative;
  display: inline-block;
  .ico-absolute {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

svg {
  &.tooltip-ico {
    margin-left: 5px;
    margin-bottom: 3px;
  }
}

.area-card {
  .card-container {
    margin: 40px 0 100px;
  }
  &.simple-card {
    .card-container {
      margin: 10px 0;
    }
  }
  .card,
  .simple-box {
    margin: 20px 0;
    border: 0;
    background-color: $primary-lightest-1;
    @extend .h-100;
    box-shadow: none;
    @include media-breakpoint-down(sm) {
      padding: 18px 20px;
    }
    @include media-breakpoint-up(sm) {
      padding: 18px 31.7px;
    }
    .card-body {
      padding: 0;
    }
    .logo-card,
    .logo-box {
      margin-top: -2.8rem;
      float: right;
      @include media-breakpoint-down(sm) {
        margin-right: -15px;
      }
    }
    .card-title,
    .title-simple-box {
      margin-bottom: 20px;
    }
    .margin-title-box {
      margin-bottom: 34px;
    }
    .h5,
    h5 {
      font-size: $font-medium;
      font-weight: $font-w-bold;
      color: $second-color;
      text-transform: uppercase;
    }
    .list-group {
      padding-top: 28px;
      .list-group-item {
        padding: 0.68rem 0;
        span,
        strong {
          width: 50%;
        }
      }
    }
  }
}

svg {
  max-width: 100%;
  &.ico-box-base {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $third-color;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
    .ico-text {
      font-size: $font-small;
      font-weight: $font-w-bold;
      line-height: 17px;
      fill: $primary;
      text-transform: uppercase;
    }
  }
}

.tab-content {
  .number-row {
    padding-top: 70px;
    padding-bottom: 70px;
    font-size: $font-medium;
    @include media-breakpoint-down(md) {
      padding-bottom: 37px;
      padding-top: 37px;
    }
    strong {
      font-size: $font-lg;
      font-weight: $font-w-bold;
      color: $second-color;
      display: block;
    }
    .number {
      font-size: $font-xlg-2;
      line-height: $font-xlg-2;
      font-weight: $font-w-bold;
      color: $second-color;
      padding-right: 0;
      @include media-breakpoint-down(md) {
        font-size: $font-lg-3;
        line-height: $font-lg-3;
      }
    }
    .col-md-4 {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
  }
}

.bb {
  margin-top: 2em;
}

p.va-desc {
  color: $primary-light;
  font-size: $font-small;
  margin-top: 5px;
}

.span-legend {
  width: 100%;
  line-height: 2.5em;
  display: inline-block;
}

.value-label {
  span[data-trans='A0191'] {
    @extend .d-flex;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  .modal-content {
    height: 100%;

    .modal-body {
      overflow: auto;
    }
  }
}

.fade.modal.show {
  padding-left: 0 !important;
}

//react-select multiselect to head
.css-g1d714-ValueContainer {
  flex-direction: column;
  align-items: flex-start !important;
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
}

.multiselect-no-flex {
  .css-g1d714-ValueContainer {
    flex-direction: unset;
    //align-items: flex-start !important;
    //-webkit-box-align: start !important;
    //-webkit-align-items: flex-start !important;
  }
}

.col-28perc {
  flex: 0 0 28%;
  max-width: 28%;
}

.col-86-33perc {
  flex: 0 0 86.33333%;
  max-width: 86.33333%;
}

.col-card {
  a {
    color: initial;

    &:hover {
      color: initial;
      text-decoration: none;
    }
  }

  .card {
    border-radius: 5%;
    max-width: 16rem;
  }
}

.ico-card-header {
  margin-bottom: 1rem;

  svg {
    height: 45px;
    fill: $primary !important;

    .ico {
      fill: $primary !important;
    }
    .esagono {
      display: none;
    }
  }
}

.ico-card-kpi {
  g {
    transform: scale(1.7) translate(1%, 20%);
  }
}
.ico-card-interventiontypes {
  g {
    transform: scale(1.7) translate(1%, 20%);
  }
}
.ico-card-classes {
  g {
    transform: scale(1.7) translate(4%, 19%);
  }
}
.ico-card-dataset {
  g {
    transform: scale(1.7) translate(4%, 15%);
  }
}
.ico-card-scenarios {
  g {
    transform: scale(1.7) translate(6%, 15%);
  }
}

.ico-card-button {
  //width: 45px;
  fill: $second-color !important;

  .ico {
    fill: $second-color !important;
  }
  .esagono {
    display: none;
  }
}

.customSmallIcon {
  height: 38px;
  width: 38px;

  path {
    stroke: #ffffff !important;
  }
}

.ag-header-cell-label .ag-header-icon.ag-sort-order {
  display: none;
}

.f-400 {
  font-weight: 400;
}
.f-500 {
  font-weight: 500;
}
.f-600 {
  font-weight: 600;
}
.f-700 {
  font-weight: 700;
}
.f-800 {
  font-weight: 800;
}
.f-900 {
  font-weight: 900;
}

.h-95 {
  height: 95%;
}

.w-space-wrap {
  white-space: normal;
}

.w-space-nowrap {
  white-space: nowrap;
}

.color-icon {
  path {
    fill: $second-color !important;
  }
}
