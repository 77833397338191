#pubblicazioni-oe, #bibliografia-essenziale{
  background: $primary-lightest;
}
.feed-oe{
  h2{
    color:$second-color;
    font-size:$font-lg;
    font-weight:$font-w-bold;
    line-height:$font-lg;
    margin:50px 0 36px 0;
    @include media-breakpoint-down(xs) {
        margin:25px 0 18px 0;
    }
  }
  .index-item{
    font-size:$font-big;
    line-height:$font-line-big;
    word-break: break-all;
    hr{
      margin:31px 0 38px 0;
    }
    h3{
      font-size:$font-medium;
      font-weight:$font-w-bold;
      margin:0;
    }
    p{
      margin-bottom:0;
      &:last-of-type{
        margin-bottom:10px;
      }
    }
    .abstract{
      font-size:$font-small-1;
      line-height:$font-small-1;
      margin-bottom:10px;
      p{
        margin-bottom:5px;
      }
    }
    .btn{
      max-width: 100%;
    }
    .btn-link, a{
      color:$primary;
    }
  }
}