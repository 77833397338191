#dashboard,
#summaryDashboard,
#monitoring {
  background: $primary-lightest;

  .impact {
    .chartBox {
      padding: 1em 1.5em;
      h5 {
        text-align: center;
        margin-top: 0.5em;
      }
      &.impactChart {
        min-width: 210px;
        width: 16.65%;
        // max-width: 250px;
        @media (max-width: 1366px) {
          width: 13%;
          min-width: 190px;
        }

        @media (min-width: 2560px) {
          width: 13%;
          margin-left: 2rem;
          margin-right: 2rem;
        }

        @media (min-width: 3840px) {
          width: 10%;
          margin-left: 5rem;
          margin-right: 5rem;
        }
        h5 {
          text-transform: uppercase;
          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
      }
      &.impactChartTab {
        min-width: 350px;
        max-width: 550px;
        width: 16.65%;
        @media (max-width: 1366px) {
          width: 20%;
          min-width: 300px;
        }

        @media (min-width: 2560px) {
          width: 25%;
          margin-left: 1rem;
          margin-right: 1rem;
        }

        @media (min-width: 3840px) {
          width: 30%;
          margin-left: 2rem;
          margin-right: 2rem;
        }
        h5 {
          text-transform: uppercase;
          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
        .listGroup-link:hover {
          color: $second-color;
        }
      }
    }
  }

  .container-card {
    //@extend .d-flex;
    .card,
    .simple-box {
      flex: 1;
      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card {
    width: 100%;
  }
}

#summaryDashboard {
  background: $primary-lightest;

  .impact {
    .chartBox {
      min-height: 270px;
      padding: 1em 1.5em;
      h5 {
        text-align: center;
        margin-top: 0.5em;
      }
      &.impactChart {
        min-width: 180px;
        width: 16.65%;
      }
      &.smallImpactChart {
        min-width: 233px;
        width: 16.65%;

        @media (max-width: 1523px) {
          width: 16.65%;
          min-width: 180px;
        }
      }
    }
  }
}

#dashboard-scenari.impact,
#newdashboard-scenari.impactMacroNew {
  margin-top: 0;

  .chartBox {
    padding: 1em;
    h5 {
      text-align: center;
      margin-top: 0.5em;
    }
    &.impactChart {
      min-width: 210px;
      width: 16.65%;
      margin-left: 1rem;
      margin-right: 1rem;

      @media (max-width: 1366px) {
        width: 13%;
        min-width: 160px;
        max-width: 190px;
        margin-left: 0;
        margin-right: 0;
      }

      @media (max-width: 1660px) {
        width: 13%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      @media (max-width: 1920px) {
        width: 13%;
        margin-left: 2rem;
        margin-right: 2rem;
      }

      @media (min-width: 2560px) {
        width: 13%;
        margin-left: 2rem;
        margin-right: 2rem;
      }

      @media (min-width: 3840px) {
        width: 10%;
        margin-left: 5rem;
        margin-right: 5rem;
      }

      h5 {
        text-transform: uppercase;
        &.impact-total {
          font-size: $font-line-lg;
          line-height: $font-line-lg;
          font-weight: $font-w-semibold;
          margin-top: 1em;
        }
      }
      /*  a {
         color: #000;
         &:hover,
         &:visited {
           color: #000;
         }
       } */
      .va-desc {
        @extend .text-center;
        font-size: $font-small;
      }
    }
  }
}

.showhide-box {
  -webkit-mask-image: linear-gradient(to bottom, black 0%, #000000a3 100%);
  mask-image: linear-gradient(to bottom, black 0%, #000000a3 100%);
}

.showhide-button {
  cursor: pointer;
  color: $second-color;

  &:hover {
    color: $second-color;
  }
}

.fade-in-div {
  animation: fadeIn linear 500ms;
  -webkit-animation: fadeIn linear 500ms;
  -moz-animation: fadeIn linear 500ms;
  -o-animation: fadeIn linear 500ms;
  -ms-animation: fadeIn linear 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out-div {
  animation: fadeOut linear 500ms;
  -webkit-animation: fadeOut linear 500ms;
  -moz-animation: fadeOut linear 500ms;
  -o-animation: fadeOut linear 500ms;
  -ms-animation: fadeOut linear 500ms;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.disabled-link {
  pointer-events: none;
  //color: white !important;
  color: #ababab !important;
  //background-color: #ababab;
  opacity: 0.65;
}

.sroi-wrap {
  @media (max-width: 1831px) {
    flex-wrap: wrap;
  }
}

.dashboardBreaks {
  @media (max-width: 1246px) {
    flex-direction: column;
  }
}
.dashboardBreaksMargin {
  @media (max-width: 1246px) {
    margin-bottom: 40px;
  }
}
.maximizeHeight {
  @media (max-width: 1246px) {
    height: 100%;
  }
}

.attachmentOuterCard {
  max-height: 250px;
  height: 100%;
}
.attachmentMainCol {
  min-width: 250px;
}

.attachmentInnerCard::-webkit-scrollbar {
  display: none;
}

.attachmentInnerCard {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

.green {
  background-color: $esg-green !important;
}

.yellow {
  background-color: $esg-yellow !important;
}

.red {
  background-color: $esg-red !important;
}

.esg-infobox {
  //max-width: inherit;

  .first-column {
    font-weight: 600;
    font-size: 1.3rem;
    white-space: nowrap;
  }

  .second-column {
    font-weight: bold;
    font-size: 1.5em;
    white-space: nowrap;
  }

  .buttongroup-esg {
    color: black !important;

    .btn-secondary {
      color: black !important;
    }

    .btn:disabled {
      opacity: 1;
    }
  }

  .fourth-column {
    .femaleaud {
      font-size: 1.5em;
      font-weight: 600;
    }

    .descr {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
}

.border-top-bottom {
  border: 3px solid rgb(91, 91, 91) !important;
}

.button-group-base {
  border-color: white !important;
  min-width: 4.7rem;
  text-shadow: 0 0 4px #9f9f9f;
  white-space: nowrap;
}

.button-group-rating {
  border-color: white !important;
  min-width: 4.7rem;
  opacity: 0 !important;
}

.showRatingESG {
  opacity: 1 !important;
  background-color: grey !important;
}

.arrow-down {
  width: 0;
  height: 0;
  margin: 0 auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(255, 255, 255);
}

.arrow-up {
  width: 0;
  height: 0;
  margin: 0 auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgb(255, 255, 255);
}
.esg-header.columns {
  font-size: 1rem;
  color: #666666;
}

.esg-header.rows {
  font-size: 1.2rem;
}

// .square-before {
//   border-radius: 50%;
//   width: 18px;
//   height: 18px;
// }
// .square-before-small {
//   border-radius: 50%;
//   width: 10px;
//   height: 10px;
// }

#totalsSelector {
  .btn.selection-button {
    max-width: 80%;
    background-color: rgba(8, 162, 170, 0.7);
    border-color: rgba(8, 162, 170, 0.7);
    text-shadow: grey 1px 0 5px;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: rgba(8, 162, 170, 0.7);
      border-color: rgba(8, 162, 170, 0.7);
      text-shadow: grey 1px 0 5px;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(8, 162, 170, 0.5);
    }
  }

  .btn.selection-button-outlined {
    max-width: 80%;
    background-color: $light-primary;
    border-color: rgba(8, 162, 170, 0.7);
    color: rgba(8, 162, 170, 0.7);
    text-shadow: rgb(191, 190, 190) 0.5px 0 2px;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $light-primary;
      border-color: rgba(8, 162, 170, 0.7);
      text-shadow: rgba(8, 162, 170, 0.7) 0.5px 0 2px;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(8, 162, 170, 0.5);
    }
  }
}
