.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.mapContainer {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.subtitle {
  font-size: 24px;
  margin: 0;
  flex-grow: 1;
  text-transform: uppercase;
  color: black;
  font-weight: 600;
}

.button {
  height: 30px;
  display: flex;
  align-items: center;

  border: none;
  /* background: ${(props) => (props.$active ? '#C3CA23' : '#c7c7c7')};
  color: ${(props) => (props.$active ? '#1C2743' : '#ebebeb')}; */
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 600;
  padding: 0 10px;
}

.buttonText {
  height: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.buttonIcon {
  height: 100%;
}
