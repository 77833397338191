#icoPage{
  background: $primary-lightest;
  .megaContent .container-fluid{
    background-color: #fff;
  }
  .card{
    width:150px;
    padding:0;
    .card-title{
      background-color: $first-color;
      color:$light-primary;
      padding:10px 20px;
    }
    svg{
      width:50px;
      margin:10px;
      &.extsvg{
        fill:$light-primary;
        .esagono{
          fill:$first-color;
        }
        &.noBox{
          .ico{
            fill:$first-color;
          }
          .esagono{
            display:none;
          }
        }
      }
    }
  }
}


/*STILI ICONE*/
.extsvg{
  &.noBox{
    .esagono{
      display:none;
    }
  }
  &.base{
    fill:$light-primary;
    .esagono{
      fill:$first-color;
    }
    &.noBox{
      .ico{
        fill:$first-color;
      }
    }
  }
}