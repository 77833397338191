#datasets {
  background: $primary-lightest;

  .impact {
    .chartBox {
      padding: 1em 3em;
      h5 {
        text-align: center;
        margin-top: 0.5em;
      }
      &.impactChart {
        min-width: 210px;
        width: 16.65%;
        h5 {
          text-transform: uppercase;
          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
      }
    }
  }

  .container-card {
    //@extend .d-flex;
    .card,
    .simple-box {
      flex: 1;
      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card {
    width: 100%;
  }
}

.modal-content {
  //background: $primary-lightest;

  .form-label {
    font-weight: bold;
  }

  .impact {
    .chartBox {
      padding: 1em 3em;
      h5 {
        text-align: center;
        margin-top: 0.5em;
      }
      &.impactChart {
        min-width: 210px;
        width: 16.65%;
        h5 {
          text-transform: uppercase;
          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
      }
    }
  }

  .container-card {
    //@extend .d-flex;
    .card,
    .simple-box {
      flex: 1;
      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card {
    width: 100%;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.5) !important;
}
.modal-backdrop {
  display: none !important;
}

.color-legend {
  max-width: 8px;
  min-width: 8px;
  width: 8px;
  border-radius: 5px;
  margin: 0.3rem 0;
  padding: 0;
}

#file-input-dat {
  display: none;
}

.divider {
  margin: 1rem 3rem 3rem 3rem;
  border-bottom: 0.5px solid #c1c1c1;
}

.disabledCountry {
  color: #c1c1c1;
}
