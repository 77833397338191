.styledTooltip {
  font-size: 12px; /* calc(32px - clamp(12px, 3vw, 20px)); */
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  line-height: 13px;
  color: black;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border: 1px solid black;
  padding: 10px;
  max-width: 200px;
  width: 100%;
  max-height: 400px;
  overflow: visible;
  box-sizing: border-box;
  pointer-events: none;
}

.styledTooltip hr {
  border-top: 1px dashed darkgray;
}

.styledRow {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
}

.styledLabel {
  color: darkgray;
  font-weight: bold;
  text-transform: uppercase;
}

.styledValue {
  font-weight: bold;
  text-align: right;
}
