.tree-item {
  position: relative;
  margin-left: 20px;
}

.toggle {
  display: inline-block;
  width: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.toggle:before {
  content: "►";
}

.toggle.open:before {
  content: "▼";
}

.child-node {
  margin-left: 30px;
  padding-left: 10px;
}