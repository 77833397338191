.input {
  border: 1px solid var(--grey);
  background-color: 'white';
  /* color: ${(props) => (props.hasError ? 'red' : 'inherited')}; */
  border-radius: 0.6rem;
  height: 3.2rem;
  padding: 0.8rem 1.2rem 0.8rem 1rem;
  min-width: 23rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  gap: 2px;
  position: relative;
  margin-bottom: 2rem;
}

.container {
  padding: 0;
  border: none;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: white;
  position: absolute;
  top: 36px;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}
.container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.container::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 2px;
}

.listButton {
  background: white;
  border: none;
  cursor: pointer;
  width: calc(100% - 8px);
  text-align: left;
  height: 32px;
  padding: 0 16px;
  margin: 4px;
}

.listButton:hover {
  background: #d1d1d154;
}

.listButton:last-child,
.listButton:first-child {
  border-radius: 4px;
}
