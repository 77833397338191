/* StyledCheckbox.module.css */

.styledCheckbox {
  text-transform: none;
  align-items: center;
  line-height: normal;
  font-style: normal;
  user-select: none;
  font-weight: 400;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  width: 100px;
}

.styledCheckbox input[type='checkbox'] {
  display: none;
}

.styledCheckbox:hover {
  background-color: #193c56;
  color: white;
  font-weight: 600;
}
.styledCheckboxDisabled:hover {
  background-color: #c8c8c8;
}

.styledCheckbox:hover span {
  border: 1px solid #ccc;
}

.styledCheckbox svg {
  margin-right: 2px;
}

.styledCheckbox span {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 4px;
  border: 1px solid #ccc;
  border-radius: 1px;
  background-color: #fff;
}

.styledCheckbox span[checked] {
  border: none;
  background-color: #1c2743;
}

.styledFilterPopup {
  position: absolute;
  left: 2px;
  top: 33px;
  border-radius: 3px;
  border: 1px solid black;
  z-index: 1;
  background-color: white;
  background: white;
}

.styledInfoTooltip {
  min-height: 24px;
  box-sizing: border-box;
  max-width: 250px;
  white-space: break-spaces;
  border-radius: 2px;
  background-color: #898989;
  padding: 5px;
  text-align: left;
  color: #fff;
  font-family: Barlow;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  padding: 15px;
}

/* .styledPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.button {
  background-color: #fff;
  color: #7f7f7f;
  border: none;
  border-radius: 4px;
  margin: 0 4px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.button.active {
  background-color: #7f7f7f;
  color: #fff;
  border: 1px solid #7f7f7f;
}

.button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
} */

/* Pagination.module.css */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.pageButton {
  background-color: #fff;
  color: #7f7f7f;
  border: none;
  border-radius: 4px;
  margin: 0 4px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.pageButtonActive {
  background-color: #7f7f7f;
  color: #fff;
  border: 1px solid #7f7f7f;
}

.pageButtonDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pageButtonHover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.styledTable {
  width: 100%;
  border-spacing: 0;
  display: table;
  background-color: #fcfcfc;
  box-sizing: border-box;
  font-family: 'Barlow', Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: #1c2743;
  table-layout: fixed;
  tr {
    width: 100%;
  }
  .styledTable tr {
    width: 100%;
  }
}

.styledHeaderCell {
  box-sizing: border-box;
  border-color: inherit;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  line-height: 13px;
  border-left: 1px solid #dbdbdb;
  padding: 8px 5px;
  /* cursor: ${({ $sortable }) => ($sortable ? 'pointer' : 'inherit')}; */
  white-space: nowrap;

  border-bottom: 1px solid #53686a43;
  position: relative;
  /* width: ${(props) => props.width || ''}; */
  font-size: 11px;
  color: #1d2842;
}

.styledHeaderCell:first-of-type {
  border-left: none;
}

.styledActionHeader {
  width: 38px;
  text-align: center;
}

.styledHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.styledContainer {
  width: 100%;
  height: 100%;
  max-height: 500px;
  /* overflow-y: ${({ $scrollable }) => ($scrollable ? 'auto' : 'hidden')}; */
  overflow-x: visible;
}
.styledContainer::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.styledContainer::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 2px;
}

.styledTableButton {
  background-color: transparent;
  border: none;
  color: white;
  padding: 0;
  cursor: pointer;
}

.styledTableCell {
  display: table-cell;
  padding: 9px 5px;
  padding-right: 14px;
  border-left: 1px solid #dbdbdb;
  text-align: left;
  /* vertical-align: middle; */
  position: relative;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 36.5px;
  /* color: #222; */
  font-family: Barlow;
  font-size: clamp(10.467px, 0.4vw, 15px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.styledTableCell:first-of-type {
  border-left: none;
}

.styledTableCell.expanded:hover {
  overflow: visible;
  z-index: 1;
}

.styledTableCell.expanded:hover span {
  background-color: #fcfcfc;
  padding-right: 10px;
  z-index: 1;
}
.styledTableCell.expandedActive:hover {
  overflow: visible;
  z-index: 1;
}

.styledTableCell.expandedActive:hover span {
  background-color: #f1f2d1;
  padding-right: 10px;
  z-index: 1;
}

.styledCellAction {
  text-align: center;
  /* border-bottom: ${(props) => (props.$isHeader ? '2px solid #49a0a8' : '')}; */
  padding: 0px;
}

.styledFilterIcon {
  height: 13px;
  width: 13px;
  margin-right: 4px;
}
