.question-code {
  // width: fit-content;
  // -moz-border-radius: 5%;
  // border-radius: 5%;
  // border: solid 2px $third-color;
  // border-radius: 15px;
  // color: $first-color;
  // text-align: center;
  // display: block;
  // font-size: small;
  // font-weight: 400;
  // padding: 0 0.3rem;
  background-color: #08a2aa3d;
  border-radius: 15px;
  color: #2c3d68;
  font-weight: $font-w-bold;
  font-size: 0.8rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}
.parent-accordion {
  border-radius: 15px;
  // box-shadow: none;
}
.child-accordion {
  border-radius: 7px;
  // box-shadow: none;
}

.specificyColor {
  accent-color: $second-color;
}

.header-center-with-filter {
  .ag-cell-label-container {
    span.ag-header-icon {
      position: absolute;
      right: 10px;
    }

    span.ag-filter-icon {
      position: relative;
      right: 5px;
    }

    div.ag-header-cell-label {
      .ag-sort-ascending-icon,
      .ag-sort-descending-icon {
        position: absolute;
        left: -70%;
      }
    }
  }
}

.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.not-saved-text {
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
}

.analyses-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  padding: 1rem 1.5rem;
  margin: 1.5rem 0 3rem;
  max-height: 500px;
  overflow: auto;
  scrollbar-color: grey rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 7px; /* width of the scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.03); /* color of the track */
  }
  &::-webkit-scrollbar-thumb {
    background: grey; /* color of the scroll thumb */
    border-radius: 5px;
  }
}

.esg-analysis-card {
  position: relative;
  width: 100%; /* Adjust as needed */
  max-width: 230px; /* Adjust as needed */
  background-color: white;
  max-height: 180px;
  height: 180px;
  border-radius: 10px;
  padding: 20px 10px;
  transition: all 0.3s ease;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: default;
  &.deactivated {
    filter: blur(3px);
  }

  &:hover {
    transform: scale(1.05);
    filter: none;
  }
}

.esg-analysis-card:hover .button-container {
  bottom: 10px;
  opacity: 1;
}

@media (hover: none) {
  .button-container {
    bottom: 10px;
    opacity: 1;
  }
}

.esg-analysis-companies-card {
  width: 100%; /* Adjust as needed */
  // max-width: 170px; /* Adjust as needed */
  // max-height: 140px;
  height: 140px;
  border-radius: 3px;
  cursor: pointer;
}

.esg-company-logo {
  width: 70px;
  height: 70px;
}

.placeholder-company-esg {
  width: 80px;
  height: 80px;
  font-size: 0.8rem;
}

.corporate-esg-company-selector-wrapper {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 500px;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
}

.box-materiality {
  height: 1.25rem;
  width: 5rem;
  margin-right: 2rem;
}

.box-materiality.green-1 {
  background-color: #689689;
}

.box-materiality.green-2 {
  background-color: #82b58f;
}

.box-materiality.green-3 {
  background-color: #9cd394;
}

.box-materiality.grey {
  background-color: #cdcdcd;
}

.svg-materiality {
  height: 4.5rem;
  width: 4.5rem;
  color: #333;
  margin-right: 3rem;

  @media (min-width: 1960px) {
    margin-right: 3.5rem;
  }

  .span.env {
    text-decoration: underline;
    text-decoration-color: #c2c923;
    -webkit-text-decoration-color: #c2c923; /* Safari */
  }
}

.gap-title {
  //gap: 2rem;

  @media (min-width: 2236px) {
    gap: 4rem;
  }

  @media (min-width: 4096px) {
    gap: 0rem;
  }
}

#row-materiality-results {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

#show-more {
  color: inherit !important;
  text-decoration: underline;
  margin: 1rem;
  text-transform: inherit !important;
}
