#scenari,
#users,
#organizations,
#kpis,
#classes,
#translations,
#modelling,
#nuoviscenariimpact,
#countryprofile {
  background: $primary-lightest;
  .cards-maps {
    background-color: $light-primary;
    background: url('../img/map.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .number-row {
    @include media-breakpoint-down(sm) {
      .card {
        font-size: $font-base;
      }
    }
    @include media-breakpoint-up(md) {
      .card {
        font-size: $font-big;
      }
    }
  }
}

div.scenaritab {
  .scenario-link {
    color: $second-color;
  }
}

span.scenario-details {
  font-size: 0.9rem;
  text-transform: none;
  color: #666;
  font-weight: normal;
  padding: 0 1em;
  border-right: 1px solid #666;
  &.no-pipe {
    border-right: none;
  }
}
.bluicon {
  color: #08a2aa;
}

.w150 {
  min-width: 150px;
}

.new-scenari-table-sort-btn {
  width: 10px;
  height: 10px;
  filter: invert(53%) sepia(95%) saturate(2697%) hue-rotate(147deg) brightness(88%) contrast(94%);
}

.new-scenarios-table-icons {
  vertical-align: -0.2em;
}
