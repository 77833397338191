.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  max-height: 75vh;
  min-height: 400px;
}
.editor-class {
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  border: 1px solid #ccc;
  max-height: 70vh;
  min-height: 350px;
  overflow-y: scroll;
  color: black !important;
}
.toolbar-class {
  border: 1px solid #ccc;
  a {
    color: black;
  }
}

#markdown-edit {
  .previewContainerMdEditor {
    height: 100%;
    padding: 0.375rem 0.75rem;
  }
  label {
    button {
      font-size: 0.8rem;
      svg {
        width: 14px;
        height: 100%;
      }
    }
  }
}
